.pagination-btn button{
    border: none;
    font-size: 18px;
    padding: 0px 8px 0px 8px;
}




.pagination-btn button:hover{
    background-color: #0f75bc;
    color: white;
}

.btn-pagination .isActives{
    background-color: #0f75bc;
}

.isActive{
    background-color: #0f75bc !important;
    color: white !important;
}

.tabButton:hover{
    background-color: #0f75bc !important;
    color: white !important;
}


.mainPageBtn:hover{
    background-color: #0f75bc !important;
    color: white !important;
}